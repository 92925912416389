// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-additionals-blender-projekte-js": () => import("./../../../src/pages/additionals-blender-projekte.js" /* webpackChunkName: "component---src-pages-additionals-blender-projekte-js" */),
  "component---src-pages-additionals-happy-variationen-js": () => import("./../../../src/pages/additionals-happy-variationen.js" /* webpackChunkName: "component---src-pages-additionals-happy-variationen-js" */),
  "component---src-pages-additionals-inventionen-js": () => import("./../../../src/pages/additionals-inventionen.js" /* webpackChunkName: "component---src-pages-additionals-inventionen-js" */),
  "component---src-pages-additionals-mixing-kinderhauslied-js": () => import("./../../../src/pages/additionals-mixing-kinderhauslied.js" /* webpackChunkName: "component---src-pages-additionals-mixing-kinderhauslied-js" */),
  "component---src-pages-additionals-mixing-project-1-js": () => import("./../../../src/pages/additionals-mixing-project-1.js" /* webpackChunkName: "component---src-pages-additionals-mixing-project-1-js" */),
  "component---src-pages-additionals-mixing-project-2-js": () => import("./../../../src/pages/additionals-mixing-project-2.js" /* webpackChunkName: "component---src-pages-additionals-mixing-project-2-js" */),
  "component---src-pages-additionals-mixing-project-3-js": () => import("./../../../src/pages/additionals-mixing-project-3.js" /* webpackChunkName: "component---src-pages-additionals-mixing-project-3-js" */),
  "component---src-pages-additionals-mixing-project-4-js": () => import("./../../../src/pages/additionals-mixing-project-4.js" /* webpackChunkName: "component---src-pages-additionals-mixing-project-4-js" */),
  "component---src-pages-additionals-sintel-morgenstern-js": () => import("./../../../src/pages/additionals-sintel-morgenstern.js" /* webpackChunkName: "component---src-pages-additionals-sintel-morgenstern-js" */),
  "component---src-pages-additionals-sintel-projekte-js": () => import("./../../../src/pages/additionals-sintel-projekte.js" /* webpackChunkName: "component---src-pages-additionals-sintel-projekte-js" */),
  "component---src-pages-errata-js": () => import("./../../../src/pages/errata.js" /* webpackChunkName: "component---src-pages-errata-js" */),
  "component---src-pages-extras-js": () => import("./../../../src/pages/extras.js" /* webpackChunkName: "component---src-pages-extras-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-print-js": () => import("./../../../src/pages/print.js" /* webpackChunkName: "component---src-pages-print-js" */),
  "component---src-pages-roadmap-js": () => import("./../../../src/pages/roadmap.js" /* webpackChunkName: "component---src-pages-roadmap-js" */),
  "component---src-pages-software-js": () => import("./../../../src/pages/software.js" /* webpackChunkName: "component---src-pages-software-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-pages-warum-js": () => import("./../../../src/pages/warum.js" /* webpackChunkName: "component---src-pages-warum-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-oer-js": () => import("./../../../src/templates/oer.js" /* webpackChunkName: "component---src-templates-oer-js" */)
}

